<style lang="sass" scoped>
.container-sample
  font-size: 12px
</style>
<template lang="pug">
div.container-sample.row(style='width: 1800px; height: 100vh')
  .card.mb-2
    .card-header 로컬에서 작동하는 샘플
    .card-body
      button(type='button' @click='add_table()') new table from scratch
      button(type='button' @click='add_csv()') new table from csv
      button(type='button' @click='add_sql()') new table from sql

    .card.mb-2(v-show='current_tab_add_table == `csv`')
      .card-header new table from csv
      .card-body
        p paste csv
        button(type='button' @click='pour_sample_csv(1)') pour sample 1
        button(type='button' @click='pour_sample_csv(2)') pour sample 2
        textarea.form-control(v-model='input_csv')
        button(type='button' @click='add_table_csv()') save
    .card.mb-2(v-show='current_tab_add_table == `sql`')
      .card-header new table from sql
      .card-body
        p paste csv
        textarea.form-control()
        button(type='button') save
    .card.mb-2(style='height: 50vh')
      .card-header logs
      .card-body(style='overflow: scroll')
        pre.code(v-for='log in logs') {{log.d}} {{log.m}} {{log.o}}
  .card.mb-2
    .card-header 테이블
    .card-body
      ul
        li(v-for='row in tables')
          a(href='#' v-show='!row.editing' @click.prevent='open_table(row)') {{row.name}}
          input(v-show='row.editing' v-model='row.name' @blur='save_table_row(row)')
          a(href='#' @click.prevent='row.editing = !row.editing') 이름변경
          a(href='#' @click.prevent='remove_table_row(row)') 삭제
          strong(v-if='row.has_contact') 고객테이블
          strong(v-if='!row.has_contact') 일반테이블

  .card.mb-2(style='width: 500px' v-if='current_table.id')
    .card-header 테이블 조회
    .card-body
      //- button(type='button') csv import
      //- button(type='button') sql import
      //- p 타입을 .. uuid customerid text refid
      p 테이블 내용 {{rows.length}}건
      //- pre {{rows}}
      div(v-if='current_field.id')
        strong Editing field
        input(type='text' v-model='current_field.label' @blur='save_field()')
        br
        button(v-for='field_format in field_formats' type='button' @click='save_field_format(field_format)' v-if='field_format.table_id != current_table.id') {{field_format.label}}
          b-icon-check(v-if='field_format.value == current_field.format && (field_format.value == `@table` ? field_format.table_id == current_field.table_id : false)')
        br
        button(type='button' @click='remove_field(current_field)') remove_field
        br
        //- .border(v-if='current_field.format == `@table`')
          p 해당테이블 필드들
          //- pre {{ get_field_by_table_id(current_field.table_id) }}
          button(v-for='external_field in get_field_by_table_id(current_field.table_id)' type='button' @click='save_field_id(external_field.id)') {{external_field.label}} {{external_field.label}}
            b-icon-
      pre {{rows}}
      div(style='overflow: scroll;')
        table.table.table-bordered
          thead
            tr
              th(v-for='col in current_table.cols' @click='edit_field(col)')
                span {{col.label || 'Field'}}
                small.text-muted {{ get_field_by_col(col) && get_field_by_col(col).label }}
              th
                a(href='#' @click='add_table_field()') add custom field
          tbody
            tr
              td(v-for='col in current_table.cols')
                input(type='text' v-model='current_table.form[col.id]' @keydown.enter='add_table_form()')
              td
                button(type='button' @click='add_table_form()') 저장
            tr(v-for='row in rows')
              td(v-for='col in current_table.cols')
                span(v-if='col.format == `@table`')
                  a(href='#' onclick='alert(`조회페이지 띄우기`)' v-b-popover.hover="'ddd'") {{row[col.id]}}
                span(v-else) {{row[col.id]}}
              td
                a(href='#' @click='open_table_row(row)') 조회
                a(href='#' @click='remove_table_item(row)') 삭제
      .card.mb-2
        .card-header 테이블 설정
        .card-body
          p sql 수정
  .card.mb-2(v-if='current_row.id' style='width: 500px')
    .card-header 내용 조회
    .card-body
      pre {{current_row}}
      table.table
        tbody
          tr(v-for='col in current_table.cols')
            th.bg-light {{ col.label }}
            td
              input(type='text' v-model='current_row_copy[col.id]' @blur='save_row_item()')
  //- .card.mb-2
    .card-header 메시지 발송목록
    .card-body
      p 보고있는 테이블 = {{current_table}}
      pre {}
</template>

<script>

import moment from "moment"
const Papa = require('papaparse')

let ID = moment().unix()
let TABLE_ID = moment().unix()

export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    this.log('loading from localStroage')
    const err = this.load()
    if (err) {
      return this.log('not loaded', err)
    }
    this.log('loaded')

    // this.open_table(this.tables[2])
  },
  data() {
    return {
      logs: [],
      current_tab_add_table: '',
      input_csv: '',

      current_table: '',
      current_field: {
        id: 0,
      },
      current_row: { id: 0},
      tables: [],
      tables_data: [],
      rows: [],

      field_formats: [],
    }
  },
  methods: {
    log(m, o = null) {
      this.logs.unshift({
        d: moment().format('HH:mm:ss'),
        m: m,
        o: o,
      })
    },
    save() {
      this.$nextTick(() => {
        {
          const d = JSON.stringify(this.tables)
          if (d != localStorage.__tables) {
            this.log('localStorage.__tables changed')
            localStorage.__tables = d
          }
        }
        {
          const d = JSON.stringify(this.tables_data)
          if (d != localStorage.__tables_data) {
            this.log('localStorage.__tables_data changed')
            localStorage.__tables_data = d
          }
        }

      })
    },

    load() {
      try {
        if (localStorage.__tables) {
          this.tables = JSON.parse(localStorage.__tables).map(e => {
            e.editing = false
            if (e.cols === undefined) e.cols = []
            return e
          })
        }
        if (localStorage.__tables_data) {
          this.tables_data = JSON.parse(localStorage.__tables_data).map(e => {
            // e.editing = false
            return e
          })
        }
        this.load_field_formats()
      } catch (error) {
        return error.message
      }
    },
    load_field_formats() {
      this.field_formats = ['uuid', 'text', '@고객ID', '@고객이름', '@고객이메일', '@고객휴대폰'].map(e => {
        return {
          label: e,
          value: e,
        }
      }).concat(this.tables.map(e => {
        return {
          label: e.name,
          value: '@table',
          table_id: e.id,
        }
      }))
      this.log('field_formats', this.field_formats)
    },
    add_table() {
      const id = TABLE_ID++
      this.tables.push({
        id,
        name: '새 목록 ' + (+this.tables.length + 1),
        editing: false,
        has_contact: false,
        cols: [],
      })
      this.save()
    },
    add_csv() {
      this.log(`this.current_tab_add_table = 'csv'`)
      this.current_tab_add_table = 'csv'
    },
    add_sql() {
      this.log(`this.current_tab_add_table = 'sql'`)
      this.current_tab_add_table = 'sql'
    },
    save_table_row(row) {
      this.save()
      row.editing = false
    },
    remove_table_row(row) {
      this.tables = this.tables.filter(e => e.id != row.id)
      this.tables_data = this.tables_data.filter(e => e.table_id != row.id)
      this.save()
    },
    open_table(row) {
      this.log('open_table', row)
      this.current_table = row
      this.current_table.form = {}
      this.rows = this.tables_data.filter(e => +e.table_id == +row.id)
      this.log('open_table rows=', this.rows.length)
      // console.log(this.tables_data)

      this.current_field = {}
      this.current_row = {}
    },
    add_table_field() {
      this.current_table.cols.push({
        id: this.current_table.cols.length + 1,
        label: 'Field',
        format: 'text',
      })
      this.save()
      this.edit_field(this.current_table.cols[this.current_table.cols.length-1])
    },
    edit_field(col) {
      // this.current_field = Object.assign({}, col)
      this.log('edit_field', col)
      this.current_field = col
      this.current_field_copy = Object.assign({}, col)
    },
    remove_field(col) {
      this.current_table.cols = this.current_table.cols.filter(e => e.id != col.id)
      this.current_field = {
        id: 0,
      }
      this.save()
    },
    save_field(col) {
      // this.current_table.cols = this.current_table.cols.map(e => {
      //   if (e.id == col.id) {

      //   } else {
      //     return e
      //   }
      // })
      this.save()
    },
    save_field_format(format) {
      // if (this.current_field_copy.format == format.value) return
      this.log('save_field_format', format)

      if (format.value == 'uuid') {
        if (this.current_table.cols.filter(e => e.format == 'uuid').length) {
          return alert('only one field can be uuid')
        }
        this.current_field_copy.format == format.value
      }
      if (format.value == '@고객ID') {
        if (this.current_table.cols.filter(e => e.format == '@고객ID').length) {
          return alert('only one field can be @고객ID')
        }
        this.current_field_copy.format == format.value
      }
      if (format.value == '@고객이름') {
        if (this.current_table.cols.filter(e => e.format == '@고객이름').length) {
          return alert('only one field can be @고객이름')
        }
        this.current_field_copy.format == format.value
      }
      if (format.value == '@고객이메일') {
        if (this.current_table.cols.filter(e => e.format == '@고객이메일').length) {
          return alert('only one field can be @고객이메일')
        }
        this.current_field_copy.format == format.value
      }
      if (format.value == '@고객휴대폰') {
        if (this.current_table.cols.filter(e => e.format == '@고객휴대폰').length) {
          return alert('only one field can be @고객휴대폰')
        }
        this.current_field_copy.format == format.value
      }
      if (format.value == '@table') {
        this.current_field.table_id = format.table_id
        this.current_field_copy.table_id = format.table_id
      } else {
        this.current_field.table_id = undefined
        this.current_field_copy.table_id = undefined
      }

      // if (this.current_field_copy.format == '@table' && format.value != '@table') {
      //   if (!confirm('trying to table field '))
      // }
      this.current_field.format = format.value
      this.current_field_copy.format = format.value
      this.save()
    },
    get_field_by_format(format) {
      return this.field_formats.filter(e => e.value == format)[0]
    },
    get_field_by_col(col) {
      return this.field_formats.filter(e => {
        if (e.value == '@table') {
          return (e.table_id == col.table_id)
        } else {
          return (e.value == col.format)
        }
      })[0]
    },
    add_table_form() {
      const form = Object.assign({
        id: ID++,
      }, this.current_table.form)
      this.rows.unshift(form)
      this.tables_data.unshift(form)
      this.save()
      this.current_table.form = {}
    },
    remove_table_item(row) {
      if(!confirm('delete?')) return false
      this.log('remove_table_item', row)
      this.rows = this.rows.filter(e => e.id != row.id)
      this.tables_data = this.tables_data.filter(e => e.id != row.id)
      this.save()
    },
    open_table_row(row) {
      this.log('open_table_row', row)
      this.current_row = row
      this.current_row_copy = Object.assign({}, row)
    },
    save_row_item() {
      this.current_row = Object.assign(this.current_row, this.current_row_copy)
      this.tables_data = this.tables_data.map(e => {
        if (e.id == this.current_row.id) {
          return this.current_row_copy
        }
        return e
      })
      this.save()
    },




    pour_sample_csv(n) {
      if (n == 1) {
        this.input_csv = `이름	전화번호	이메일
테스트1	010-4102-9921	jinhyuk.lee@studiointo.asia
테스트2	010-4102-9921	jinhyuk.lee@studiointo.asia
테스트3	010-4102-9921	jinhyuk.lee@studiointo.asia`
      }
    },
    add_table_csv() {
      this.log('try parse')
      const r = Papa.parse(this.input_csv)
      this.log('parsed', r)

      const id = TABLE_ID++
      const cols = r.data[0].map((e, i) => {
        return {
          id: i+1,
          label: e,
          format: 'text',
        }
      })
      this.tables.push({
        id,
        name: '새 목록 ' + (+this.tables.length + 1),
        editing: false,
        has_contact: false,
        cols,
      })
      for (const [i, data] of Object.entries(r.data)) {
        // skip header
        if (i == 0) continue

        const v = {
          table_id: id,
          id: ID++,
        }
        for (const [cidx, col] of Object.entries(cols)) {
          v[col.id] = data[cidx]
        }

        this.tables_data.push(v)

      }
      this.save()

    },
  },
}
</script>
